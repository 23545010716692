// // apiService.js
// import axios from 'axios';
import { API_URL } from "../Components/Common/ApplicationConst";
import axios from "axios";
import { getSecureLSData } from "../utils/slsConfig";

const api = axios.create({
  baseURL: API_URL,
});


let cancelToken = axios.CancelToken.source();

// Interceptor for handling rquest
api.interceptors.request.use(
  (config) => {
    const token = getSecureLSData('token');
    config.headers.Authorization = `Barear ${token}`
    config.headers["Content-Type"] = 'application/json'
    config.cancelToken = cancelToken.token;
    return config
  }
);

export const cancelRequests = () => {
  if (cancelToken) {
    cancelToken.cancel('Request canceled due to component change.');
  }
  cancelToken = axios.CancelToken.source(); // Reset the cancel token
};

export default api;
