import React from 'react';

const SignUp1 = React.lazy(() => import('./Components/Authentication/SignUp/SignUp1'));
const Signin1 = React.lazy(() => import('./Components/Authentication/SignIn/SignIn1'));
const setPassword = React.lazy(() => import('./Components/Authentication/SetPassword'));
const ForgotPassword = React.lazy(() => import('./Components/Authentication/ForgotPassword'));

const route = [
    { path: '/auth/signup-1', exact: true, name: 'Signup 1', component: SignUp1 },
    { path: '/login', exact: true, name: 'Signin 1', component: Signin1 },
    { path: '/forgotpassword', exact: true, name: 'Forgot Password', component: ForgotPassword },
    {
        path: '/auth/setPassword/:id', exact: true, name: 'User', component: setPassword
    },
    {
        path: '/auth/forgotPassword/:id', exact: true, name: 'User', component: setPassword
    }
];

export default route;