// export const API_URL = "https://localhost:7207/";
export const API_URL = process.env.REACT_APP_SERVER_API;


export const GetOffice_URL = "api/Office/getoffice";
export const PostOffice_URL = "api/Office/postoffice";
export const PutOffice_URL = "api/Office/putoffice";
export const DeleteOffice_URL = "api/Office/deleteoffice?id=";

export const GetUser_URL = "api/User/getuser";
export const PostUser_URL = "api/User/postuser";
export const PutUser_URL = "api/User/putuser";
export const DeleteUser_URL = "api/User/deleteuser?id=";
export const GetApplicationObject_URL = "api/User/getusertype";
export const UserConfirmation_URL = "api/User/userverified?id=";
export const SetPassword_URL = "api/User/setpassword";
export const GetCountry_URL = "api/User/getcountry";
export const Profile_URL = "api/User/profile";
export const GetUserLookup_URL = "api/User/getUserLookup";
export const ResendActivation_URL = "api/User/resendactivation";

export const PostClient_URL = "api/Client/postclient";
export const GetClientStatus_URL = "api/Client/getclientstatus";
export const GetClient_URL = "api/Client/getclient";
export const PutClient_URL = "api/Client/putclient";
export const DeleteClient_URL = "api/Client/deleteclient?id=";
export const GetClientById_URL = "api/Client/getclientbyid?id=";
export const GetClientLocationById_URL = "api/Client/getclientdepartmentlocationmappingsbyclientId?id=";
export const GetClientFilter_URL = "api/Client/getclientFilter";
export const GetCountryAndState_URL = "api/Client/getcountryandstatus";


export const PostClientContact_URL = "api/ClientContact/postclientcontact";
export const getClientContactByClientId_URL = "api/ClientContact/getclientcontactbyclientid?id=";
export const DeleteClientContactByClientId_URL = "api/ClientContact/deleteclientcontact?id=";
export const GetClientByClientContactId_URL = "api/ClientContact/getclientcontactbyclientcontactid?id=";
export const PutClientContact_URL = "api/ClientContact/putclientcontact";

export const ResetPassword_URL = "api/Authentication/resetpassword";
export const Start_End_Time_Validation = 'End Time can not be less than Start Time when the start and end dates are on the same day.';
export const StaffCount_Validation = 'Number of staff requested cannot be less than the number of candidates confirmed. Please contact your consultant.';
export const Login_URL = "api/Authentication/login"
export const Logout_URL = "api/Authentication/logout"
export const USERINFO_URL = "api/Authentication/loggedinuserinformation"

export const GetClassificationOfWorker_URL = "api/Job/getclassificationofworker";
export const GetSearchJobs_URL = "api/Job/getsearchjobs";
export const GetJobById_URL = "api/Job/getjobbyid?id=";
export const PostJob_URL = "api/Job/postjob";
export const GetClients_URL = "api/Job/getclients";
export const PutJob_URL = "api/Job/putjob";
export const DeleteJob_URL = "api/Job/deletejob?id=";
export const ConfirmJob_URL = "api/Job/confirmJob";
export const GetClientForLinked_URL = "api/ClientContact/getclientforlinked";
export const PostLinkedClient_URL = "api/clientcontact/linkedclients";
export const getlinkedclientsbyclientcontactid = "api/clientcontact/getlinkedclientsbyclientcontactid?id=";
export const CancelJob_URL = "api/Job/cancelJob";
export const GetClientContactlookup_URL = "api/ClientContact/getclientcontactlookup";


export const ForgotPassword_URL = "api/User/forgotpassword";

export const GetJobStatus_URL = "api/Job/getjobsatus";
export const GetOfficeByUserId_URL = "api/clientcontact/getofficebyuserid";

export const DeleteLinkClient = 'api/clientcontact/deletelinkedclient?id=';
export const GetJobStatusChart_URL = "api/Dashboard/postjobsstatuschart";
export const GetDashboardChart_URL = "api/Dashboard/getdashboardchart";
export const Getclientchart_URL = "api/Dashboard/getclientchart";
export const Getclientcontactchart_URL = "api/Dashboard/getclientcontactchart";
export const GetJobWorkingDaysChart_URL = "api/Dashboard/postjobsworkingdayschart";

export const ChartType = {
    JobStatusChart: 0,
    JobByWorkingDaysChart: 1,
    JobStatusByMonthsChart: 2,
    ClientCountChart: 3,
    ClientContactCreatedChart: 4,
}

//Message
export const DeleteOffice_Success = "Office Deleted Successfully";
export const AddOffice_Success = "Office Added Successfully";
export const UpdateOffice_Success = "Office Updated Successfully";
export const SomethingWentWrong = "Something went wrong";
export const UserActivation_Success = "User activation link has been sent to the user's email";

export const DeleteClient_Success = "Client Deleted Successfully";
export const DeleteClientContact_Success = "Client Contact Deleted Successfully";
export const NewPasswordLink_Success= 'Link to set a new password has been sent to your registered Email Address';
export const SetPassword_Success= 'Password Set Successfully';

export const DeleteUser_Success = "User Deleted Successfully";
export const AddUser_Success = "User Added Successfully";
export const UpdateUser_Success = "User Updated Successfully";
export const Profile_Success = "Profile Updated Successfully";

export const ResetPassword_Success = "Password Reset Successfully";

export const AddJob_Success = "Job Request has been Successfully Submitted";
export const UpdateJob_Success = "Job Updated Successfully";
export const SavedJob_Success = "Job Saved Successfully";
export const DeleteJob_Success = "Job Deleted Successfully";
export const ConfirmJob_Success = "Job Status Changed Successfully";
export const OfficeUseInClient = "Clients are already linked to the selected office for this client contact"
export const StatusChangeFromCancel = "This request has been cancelled. Please raise a new request."
export const StatusChangeFromConfirmToRequest = "This shift booking has been confirmed, please raise a new request."
export const StatusChangeFromPartiallyConfirmToRequest = "This shift booking has been partially confirmed, please raise a new request."
export const WorkingDaySelectValidation = "Please select atleast one working day."
export const JobStatusNeedToChangeValidation = "Job status needs to be changed."
export const AddAtleastOneCandidateValidation = "While confirming a job, at least one candidate should be added."
export const StaffRequiredCountOnCancelJob = "Staff Required Count cannot be changed on a cancelled job."
export const StaffRequiredCountOnConfirmedJob = "Number of staff requested cannot be less than the number of candidates confirmed. Please contact your consultant."
export const JobCancelSuccessMessage = "Job has been cancelled successfully."

export const FormNotValidate = "Form is not edited"

export const ClassificationOfWorker_Max_Length = "Worker Position / Classification should contain maximum length of 50 characters";
export const UserExist = "User already exists";
export const OfficeExist = "Office already exists";
export const JobAlreadyCancelled = "Job is already cancelled";

export const SystemAdminUserType = 1
export const RecruiterUserType = 2
export const ClientContactUserType = 3

export const JobStatusRequested = 12;
export const ConfirmJobstatus = 13;
export const JobStatusCancelled = 14;
export const JobStatusPartiallyConfirm = 15;

export const WorkingDays = {
    Monday: 16,
    Tuesday: 17,
    Wednesday: 18,
    Thursday: 19,
    Friday: 20,
    Saturday: 21,
    Sunday: 22,
}
