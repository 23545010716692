import 'primeflex/primeflex.css'; // css utility
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css'; // theme
import React, { Component, Suspense } from 'react';
import Loadable from 'react-loadable';
import { Route, Switch, withRouter } from 'react-router-dom';
import '../../node_modules/font-awesome/scss/font-awesome.scss';
import api from '../Services/ApiService';
import Aux from "../hoc/_Aux";
import routes from "../route";
import { removeSecureLSData } from '../utils/slsConfig';
import Loader from './layout/Loader';
import { Toast } from 'primereact/toast';

const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading: Loader
});

class App extends Component {
    constructor(props) {
        super(props);
        this.toast = React.createRef();
        this.state = {
            visible: false,
            errorMessage: '',
            showButtonDisabled: false
        }


    }
    componentDidMount() {
        api.interceptors.response.use(
            async (response) => {
                return response
            },
            (error) => {
                if (error.response?.status === 401) {
                    if (!this.state.showButtonDisabled) {
                        this.toast.current.show({
                            severity: 'error', onHidden: () => {
                                this.setState({ showButtonDisabled: false });
                            }, summary: 'Error', detail: "Unauthorized Access", life: 3000, toastId: 'success1',
                        });
                        this.setState({ showButtonDisabled: true });
                        this.handleRedirect();
                    }
                }
                if (error.response?.status === 403) {
                    if (!this.state.showButtonDisabled) {
                        this.toast.current.show({
                            severity: 'error', onHidden: () => {
                                this.setState({ showButtonDisabled: false });
                            }, summary: 'Error', detail: "Same user already logged-in", life: 3000, toastId: 'success1',
                        });
                        this.setState({ showButtonDisabled: true });
                        this.handleRedirect();
                    }
                }

                if (error.response?.status === 500) {
                    this.toast.current.show({
                        severity: 'error', onHidden: () => {
                            this.setState({ showButtonDisabled: false });
                        }, summary: 'Error', detail: error.response?.data, life: 3000, toastId: 'error',
                    });
                    throw new Error("error");
                }
            }
        )
    }
    handleRedirect = () => {
        this.setState({ visible: false })
        localStorage.clear();
        removeSecureLSData("token");
        removeSecureLSData("userInfo");
        this.props.history.push('/login')
    }
    render() {
        const menu = routes.map((route, index) => {
            return (route.component) ? (
                <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={props => (
                        <route.component {...props} />
                    )} />
            ) : (null);
        });

        return (

            <Aux>
                <Toast ref={this.toast} />
                <Suspense fallback={<Loader />}>
                    <Switch>
                        {menu}
                        <Route path="/" component={AdminLayout} />
                    </Switch>
                </Suspense>
            </Aux>
        );
    }
}

export default withRouter(App);