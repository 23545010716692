import SecureLS from 'secure-ls';

// Initialize secure-ls with your secret key
const secureLS = new SecureLS({ encodingType: 'aes', isCompression: true, encryptionSecret: 'w0rk£xt' });

// Function to set data
export function setSecureLSData(key, data) {
  secureLS.set(key, data);
}

// Function to fetch data
export function getSecureLSData(key) {
  return secureLS.get(key);
}

// Function to remove data
export function removeSecureLSData(key) {
  secureLS.remove(key);
}
